import React, { useState, useEffect } from "react";
import moment from "moment";
import classes from "./Countdown.module.css"

const Countdown = ({ targetDate }) => {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  function calculateTimeLeft() {
    const now = moment();
    const target = moment(targetDate);
    const duration = moment.duration(target.diff(now));
    return {
      days: Math.floor(duration.asDays()),
      hours: duration.hours(),
      minutes: duration.minutes(),
      seconds: duration.seconds(),
    };
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(interval);
  }, [targetDate]);

  return (
    <div className={classes.main}>
      <h1>
        TIME TO EVENT START:
      </h1>
      <p>
        {timeLeft.days} days, {timeLeft.hours} hours, {timeLeft.minutes} minutes,{" "}
        {timeLeft.seconds} seconds
      </p>
    </div>
  );
};

export default Countdown