import { parseNumber } from "../../../Utils/parseNumber"
import MetricCard from "../../Common/MetricCard/MetricCard"
import classes from "./AdminEvent.module.css"

const AdminEvent = (props) => {
    const { event } = props

    console.log(event)

    const totalTickets = event.ticketsCount + event.freeTicketsCount
    const totalTicketsSum = parseNumber(event.totalTicketSum - Math.round(event.totalTicketSum / 100 * 2))
    const scannedPercent = Math.round((event.scannedCount / totalTickets) * 100);
    const unScannedPercent = Math.round((event.unscannedCount / totalTickets) * 100);

    return (
        <div className={classes.main}>
            <div className={classes.block}>
                <h3>Tickets</h3>
                <div className={classes.line}>
                    <MetricCard title="Total Tickets" value={totalTickets} type="default"/>
                    <MetricCard title="Paid" value={event.ticketsCount} type="green"/>
                    <MetricCard title="Free" value={event.freeTicketsCount} type="yellow"/>
                    <MetricCard title="Today" value={event.todayTicketsBought} type="green"/>
                </div>
            </div>
            <div className={classes.block}>
                <h3>Cash</h3>
                <div className={classes.line}>
                    <MetricCard title="Total Sum" value={`${totalTicketsSum} uah`} type="green"/>
                    <MetricCard title="Average Price" value={`${event.averagePrice} uah`} type="yellow"/>
                </div>
            </div>
            <div className={classes.block}>
                <h3>Users</h3>
                <div className={classes.line}>
                    <MetricCard title="Uniq users (new)" value={`${totalTickets - event.repeatedCountTickets}`} type="green"/>
                    <MetricCard title="Returned users" value={`${event.repeatedCountTickets}`} type="yellow"/>
                </div>
            </div>
            <div className={classes.block}>
                <h3>Tickets Status</h3>
                <div className={classes.line}>
                    <MetricCard title="Scanned" value={`${event.scannedCount} (${scannedPercent}%)`} type="green"/>
                    <MetricCard title="Unscanned" value={`${event.unscannedCount} (${unScannedPercent}%)`} type="default"/>
                </div>
            </div>
        </div>
    )
}

export default AdminEvent