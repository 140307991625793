import { useEffect } from "react"
import { connect } from "react-redux"
import { getEvent } from "../../../Redux/eventsReducer"
import AdminLayout from "../../UI/Admin/AdminLayout/AdminLayout"
import PageHeader from "../../UI/Admin/PageHeader/PageHeader"
import { useParams } from 'react-router-dom';
import Preloader from "../../Common/Preloader/Preloader"
import AdminEvent from "./AdminEvent"

const AdminEventContainer = (props) => {
    const { event, getEvent, isFetching } = props

    const { code } = useParams();

    console.log(event)

    useEffect(() => {
        if (code) {
            getEvent(code)
        }
    }, [code])

    return (
        <AdminLayout>
            {isFetching && <Preloader/>}
            {event ? (
                <>
                    <PageHeader
                        title={event.title}
                        searchBar={false}
                        createBut={false}
                        currentEvent={null}
                        isHaveBackButton={true}
                    />
                    <AdminEvent event={event}/>
                </>
            ) : <></>}
        </AdminLayout>
    )
}

let mapStateToProps = (state) => ({
    event: state.events.event,
    isFetching: state.common.isFetching,
})

export default connect(mapStateToProps, {
    getEvent
})(AdminEventContainer)