import { useEffect, useState } from "react"
import { connect } from "react-redux"
import { getEvents } from "../../../Redux/eventsReducer"
import { getPromoters } from "../../../Redux/ticketsReducer"
import Preloader from "../../Common/Preloader/Preloader"
import AdminLayout from "../../UI/Admin/AdminLayout/AdminLayout"
import PageHeader from "../../UI/Admin/PageHeader/PageHeader"
import Promoters from "./Promoters"

const PromotersContainer = (props) => {
    const { 
        isFetching,
        tickets,
        getEvents,
        events,
        getPromoters
    } = props

    const [currentEvent, setCurrentEvent] = useState(null)

    useEffect(() => {
        getEvents(1, 1000, "", "", "")
    }, [])

    useEffect(() => {
        if (events.length > 0) {
            setCurrentEvent(events[0]._id)
        }
    }, [events])

    useEffect(() => {
        getPromoters(currentEvent)
    }, [currentEvent])

    return (
        <AdminLayout>
            <PageHeader
                title="Promoters"
                searchBar={false}
                createBut={false}
                currentEvent={currentEvent}
                setCurrentEvent={setCurrentEvent}
                events={events}
            />
            {isFetching && <Preloader/>}
            <Promoters tickets={tickets}/>
        </AdminLayout>
    )
}

let mapStateToProps = (state) => ({
    isFetching: state.common.isFetching,
    tickets: state.tickets.tickets,
    events: state.events.events
})

export default connect(mapStateToProps, {
    getEvents,
    getPromoters
})(PromotersContainer)