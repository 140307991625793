import classes from "./MetricCard.module.css"

const MetricCard = (props) => {
    const { title, value, type } = props

    return (
        <div className={`${classes.main} ${classes[type]}`}>
            <h5>{title}</h5>
            <p>{value}</p>
        </div>
    )
}

export default MetricCard