import { ticketsApi } from "../Api/api"
import { setIsFetching, setServerError, setServerResponse } from "./commonReducer"

const SET_TICKETS_DATA = "SET_TICKETS_DATA"
const SET_TOTAL_TICKETS = "SET_TOTAL_TICKETS"

let initialState = {
    tickets: [],
    total: 0
}

let ticketsReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_TICKETS_DATA: {
            return { ...state, tickets: action.tickets }
        }
        case SET_TOTAL_TICKETS: {
            return { ...state, total: action.total }
        }
        default: 
            return state
    }
}

export const setTicketsData = (tickets) => ({
    type: SET_TICKETS_DATA, tickets
})

export const setTotalTickets = (total) => ({
    type: SET_TOTAL_TICKETS, total
})

export const getTickets = (pageNumber, pageSize, searchBy, from, searchingValue, isIncludeFreeTickets) => async (dispatch) => {
    dispatch(setIsFetching(true))
    try {
        let response = await ticketsApi.getTickets(pageNumber, pageSize, searchBy, from, searchingValue, isIncludeFreeTickets)
        dispatch([setTotalTickets(response.total), setTicketsData(response.tickets), setIsFetching(false)])
    } catch (err) {
        dispatch(setIsFetching(false))
    }
}

export const getPromoters = (eventId) => async (dispatch) => {
    dispatch(setIsFetching(true))
    try {
        let response = await ticketsApi.getPromoters(eventId)
        dispatch(setTicketsData(response.tickets))
    } catch (err) {
        console.log(err)
    } finally {
        dispatch(setIsFetching(false))
    }
}

export const createTicket = (data) => async (dispatch) => {
    dispatch(setIsFetching(true))
    try {
        let response = await ticketsApi.createTicket(data)
        dispatch([setServerResponse(response.message), setIsFetching(false)])
        return true
    } catch (err) {
        dispatch([setServerError(err.response.data.message), setIsFetching(false)])
        return false
    }
}

export const resendTicket = (data) => async (dispatch) => {
    dispatch(setIsFetching(true))
    try {
        let response = await ticketsApi.resendTicket(data)
        dispatch([setServerResponse(response.message), setIsFetching(false)])
        return true
    } catch (err) {
        dispatch([setServerError(err.response.data.message), setIsFetching(false)])
        return false
    }
}

export const deleteTicket = (ticketId) => async (dispatch) => {
    dispatch(setIsFetching(true))
    try {
        let response = await ticketsApi.deleteTicket(ticketId)
        dispatch([setServerResponse(response.message), setIsFetching(false)])
        return true
    } catch (err) {
        dispatch([setServerError(err.response.data.message), setIsFetching(false)])
        return false
    }
}

export default ticketsReducer