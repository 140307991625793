import classes from "./ProgressBar.module.css"

const ProgressBar = (props) => {
    const { mainProgress, subProgress, total } = props

    console.log(mainProgress, subProgress)

    function calculatePercentage(ticketsSold, totalTickets) {
        return (ticketsSold / totalTickets) * 100;
    }

    return (
        <div className={classes.main}>
            <div className={classes.labels}>
                <p>0 ПИЗДА...</p>
                <p>{total} ПИЗДА!!!!!!!!!</p>
            </div>
            <div className={classes.bar}>
                <div className={classes.mainBar} style={{ width: `${calculatePercentage(mainProgress, total)}%` }}/>
                <div className={classes.subBar} style={{ width: `${calculatePercentage(subProgress + 5, total)}%` }}/>
            </div>
        </div>
    )
}

export default ProgressBar