import Countdown from "./Countdown/Countdown"
import classes from "./Dashboard.module.css"
import ProgressBar from "./ProgressBar/ProgressBar"

const Dashboard = (props) => {
    const { upcoming } = props

    console.log(upcoming)

    return (
        <div className={classes.main}>
            {upcoming.length > 0 && <Countdown targetDate={upcoming[0].date}/>}
            {upcoming.length > 0 && (
                <ProgressBar
                    mainProgress={upcoming[0].ticketsCount}
                    subProgress={upcoming[0].ticketsCount + upcoming[0].freeTicketsCount}
                    total={2000}
                />
            )}
        </div>
    )
}

export default Dashboard