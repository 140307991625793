import React from 'react'
import classes from '../AdminView.module.css'
import { Table } from '@mui/material'
import { TableHead } from '@mui/material'
import { TableCell } from '@mui/material'
import { TableBody } from '@mui/material'
import { TableRow } from '@mui/material'
import { TableContainer, Paper } from '@mui/material'
import EmptyData from '../../UI/Admin/EmpyData/EmptyData'
import TableTh from '../../UI/Table/TableTh/TableTh'
import _ from "lodash";

const Promoters = (props) => {
    const {
        tickets
    } = props

    const promoters =  _.groupBy(tickets, "promo");
    const sortedTicketsByPromoter = Object.fromEntries(
        Object.entries(promoters).sort((a, b) => b[1].length - a[1].length)
      )

    const rows = [
        {
            key: "promoter",
            text: "Promoter Name",
            searchByValue: ""
        },
        {
            key: "count",
            text: "Tickets count",
            searchByValue: ""
        }
    ]

    return (
        <div className={classes.main}>
            <TableContainer component={Paper} className={classes.table}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {rows.map(item => (
                                <TableTh
                                    key={item.key}
                                    text={item.text}
                                />
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Object.keys(sortedTicketsByPromoter).map(function(key, index) {
                            if (key === "" || key === "undefined") {
                                return <></>
                            } else {
                                return (
                                    <TableRow key={sortedTicketsByPromoter[key][0]._id}>
                                        <TableCell>{sortedTicketsByPromoter[key][0].promo}</TableCell>
                                        <TableCell>{sortedTicketsByPromoter[key].length}</TableCell>
                                        {/* <TableCell>{item.count || 0}</TableCell> */}
                                    </TableRow>
                                )
                            }})
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            {tickets.length === 0 && <EmptyData/>}
        </div>
    )
}

export default Promoters