import { IconButton, MenuItem } from '@mui/material'
import CustomButton from '../../CustomButton/CustomButton'
import CustomSelect from '../../Form/CustomSelect'
import AdminSearch from '../Search/AdminSearch'
import classes from './PageHeader.module.css'
import { IoIosArrowBack } from "react-icons/io";
import { useNavigate } from 'react-router'

const PageHeader = (props) => {
    const { 
        title, 
        onAddClick,
        searchValue,
        setSearchValue,
        pageSize,
        onSearch,
        searchBar = true,
        createBut = true,
        isIncludeFreeTickets,
        events,
        currentEvent,
        setCurrentEvent,
        isHaveBackButton
    } = props

    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(-1);
      };

    return (
        <div className={classes.header}>
            <div className={classes.left}>
                {isHaveBackButton && (
                    <IconButton onClick={handleGoBack}>
                        <IoIosArrowBack/>
                    </IconButton>
                )}
                <h2>{title}</h2>
            </div>
            <div className={classes.right}>
                {searchBar && (
                    <AdminSearch 
                        searchValue={searchValue}
                        setSearchValue={setSearchValue}
                        pageSize={pageSize}
                        onSearch={onSearch}
                        isIncludeFreeTickets={isIncludeFreeTickets}
                    />
                )}
                {createBut && (
                    <CustomButton onClick={onAddClick} className={classes.button}>
                        Create
                    </CustomButton>
                )}
                {currentEvent && (
                    <CustomSelect
                        value={currentEvent}
                        label="Select Event"
                        onChange={(e) => setCurrentEvent(e.target.value)}
                    >
                        <MenuItem disabled>Select event</MenuItem>
                        {events.map(el => (
                            <MenuItem key={el.title} value={el._id}>{el.title}</MenuItem>
                        ))}
                    </CustomSelect>
                )}
            </div>
        </div>
    )
}

export default PageHeader