import { useEffect } from "react"
import { connect } from "react-redux"
import { getUpcomingEvents } from "../../../Redux/eventsReducer"
import AdminLayout from "../../UI/Admin/AdminLayout/AdminLayout"
import PageHeader from "../../UI/Admin/PageHeader/PageHeader"
import Dashboard from "./Dashboard"

const DashboardContainer = (props) => {
    const { getUpcomingEvents, upcoming } = props

    useEffect(() => {
        getUpcomingEvents()
    }, [])

    return (
        <AdminLayout>
            <PageHeader
                title="Dashboard"
                searchBar={false}
                createBut={false}
            />
            <Dashboard
                upcoming={upcoming}
            />
        </AdminLayout>
    )
}

let mapStateToProps = (state) => ({
    upcoming: state.events.upcoming
})

export default connect(mapStateToProps, {
    getUpcomingEvents
})(DashboardContainer)