import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Admin from '../../Pages/Admin/Admin'
import Login from '../../Pages/Auth/Login/Login'
import DashboardContainer from '../Admin/Dashboard/DashboardContainer'
import EmployeesContainer from '../Admin/Employees/EmployeesContainer'
import AdminEventContainer from '../Admin/Event/AdminEventContainer'
import AdminEventsContainer from '../Admin/Events/AdminEventsContainer'
import NewsContainer from '../Admin/News/NewsContainer'
import PartnersContainer from '../Admin/Partners/PartnersContainer'
import PromocodesContainer from '../Admin/Promocodes/PromocodesContainer'
import PromotersContainer from '../Admin/Promoters/PromotersContainer'
import TicketsContainer from '../Admin/Tickets/TicketsContainer'

const RoutesComponent = () => {
    return (
        <Routes>
            <Route path="login" element={<Login/>}/>
            <Route path="" element={<Admin/>}>
                <Route path="/" element={<DashboardContainer/>} />
                <Route path="events" element={<AdminEventsContainer/>} />
                <Route path="event/:code" element={<AdminEventContainer/>} />
                <Route path="tickets" element={<TicketsContainer/>} />
                <Route path="team" element={<EmployeesContainer/>} />
                <Route path="partners" element={<PartnersContainer/>} />
                <Route path="news" element={<NewsContainer/>} />
                <Route path="promocodes" element={<PromocodesContainer/>} />
                <Route path="promoters" element={<PromotersContainer/>} />
            </Route>
        </Routes>
    )
}

export default RoutesComponent